// import { Link, useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import _ from "lodash"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import InnerHero from "../../components/Shared/innerHero"

function CategoryCard({ category }) {
  return (
    <div className="col-lg-6 mb-4">
      <div className="card h-100">
        <div className="card-header py-3">
          <h3>{category.title}</h3>
        </div>
        <div className="card-body">
          <ul>
            {category.subCategories.map(item => (
              <SubCategory
                key={item.slug}
                items={item.links}
                title={item.title}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

function SubCategory({ items, title }) {
  return (
    <>
      <li>
        <span className="faq-sub-list-title">{title}</span>
      </li>
      {items.map((item, i) => (
        <ItemLink key={item.slug} item={item} isLast={items.length - 1 === i} />
      ))}
    </>
  )
}

function ItemLink({ item, isLast }) {
  return (
    <li className={isLast ? "mb-2" : ""}>
      <Link to={`/support/no-surrender/${item.slug}`}>{item.title}</Link>
    </li>
  )
}

function NoSurrender() {
  const gameId = "no-surrender"
  const [linkItems, setLinkItems] = useState([])
  const data = DATA

  //   const data = useStaticQuery(graphql`
  //     query QueryAll {
  //       categories: allStrapiSupportCategories {
  //         nodes {
  //           strapiId
  //           title
  //           slug
  //         }
  //       }
  //       subcategories: allStrapiSupportSubcategories {
  //         group(field: support_category___id) {
  //           categoryId: fieldValue
  //           nodes {
  //             strapiId
  //             title
  //             slug
  //           }
  //         }
  //       }
  //       subCategoryBased: allStrapiSupportPages {
  //         group(field: support_subcategory___id) {
  //           subcategoryId: fieldValue
  //           nodes {
  //             title
  //             slug
  //           }
  //         }
  //       }
  //     }
  //   `)

  useEffect(() => {
    const categories = data.categories.nodes
    const subcategories = data.subcategories.group
    const result = categories.map(item => {
      const subCategories = findSubcategoryList(subcategories, item.strapiId)
      item.subCategories = subCategories
      item.subCategories.map(subItem => {
        const subcategoryId = subItem.strapiId
        const linkGroups = data.subCategoryBased.group
        const linkGroup = _.find(linkGroups, function (o) {
          return o.subcategoryId == subcategoryId
        })
        if (!linkGroup) {
          subItem.links = []
          return []
        }
        const links = linkGroup.nodes
        subItem.links = links
        return subItem
      })
      return item
    })
    setLinkItems(result)
  }, [data])

  const findSubcategoryList = (subcategories, categoryId) => {
    const out = _.find(subcategories, function (o) {
      return o.categoryId == categoryId
    })
    return out.nodes
  }

  return (
    <Layout>
      <Seo title="No Surrender Support" />
      <InnerHero title="No Surrender Support" />
      <section className="pt-120 pb-120 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-header text-center">
                <span className="section-sub-title">GOT A QUESTION?</span>
                <h2 className="section-title">We&apos;ve got answers.</h2>
                <p>
                  If you have a question or general enquiry, we&rsquo;re here to
                  help. Complete the form below and we will get back to you as
                  soon as we can.
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-none-40 cmn-accordion">
            {linkItems.map(category => (
              <CategoryCard key={category.slug} category={category} />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NoSurrender

const DATA = {
  categories: {
    nodes: [
      {
        strapiId: 1,
        title: "Account",
        slug: "account",
        subCategories: [
          {
            strapiId: 1,
            title: "Recover Your Account",
            slug: "recover-your-account",
            links: [
              { title: "I lost my account!", slug: "i-lost-my-account!" },
              {
                title: "Recover your NoSurrender ID",
                slug: "recover-your-nosurrender-id",
              },
              {
                title: "How can I keep my account safe?",
                slug: "how-can-i-keep-my-account-safe",
              },
              {
                title: "Someone else is playing my account!",
                slug: "someone-else-is-playing-my-account!",
              },
            ],
          },
          {
            strapiId: 2,
            title: "NoSurrender ID",
            slug: "nosurrender-id",
            links: [
              { title: "About NoSurrender ID", slug: "about-nosurrender-id" },
              {
                title: "Save your account with NoSurrender ID",
                slug: "save-your-account-with-nosurrender-id",
              },
              {
                title: "Help with Verification Codes",
                slug: "help-with-verification-codes",
              },
              {
                title: "Playing with multiple game accounts",
                slug: "playing-with-multiple-game-accounts",
              },
              {
                title: "How to opt-out of marketing messages",
                slug: "how-to-opt-out-of-marketing-messages",
              },
            ],
          },
          {
            strapiId: 3,
            title: "Access/Delete Your Data",
            slug: "accessdelete-your-data",
            links: [
              { title: "About GDPR", slug: "about-gdpr" },
              {
                title: "Request your personal data",
                slug: "request-your-personal-data",
              },
              {
                title: "Request deletion of your personal data",
                slug: "request-deletion-of-your-personal-data",
              },
            ],
          },
        ],
      },
      {
        strapiId: 2,
        title: "Purchases",
        slug: "purchases",
        subCategories: [
          {
            strapiId: 4,
            title: "Missing Purchase",
            slug: "missing-purchase",
            links: [
              {
                title: "I didn't get my Android purchase!",
                slug: "i-didn't-get-my-android-purchase!",
              },
              {
                title: "I didn't get my iOS purchase!",
                slug: "i-didn't-get-my-ios-purchase!",
              },
              { title: "I can't buy offers!", slug: "i-can't-buy-offers!" },
            ],
          },
          {
            strapiId: 5,
            title: "In-App Purchases",
            slug: "in-app-purchases",
            links: [
              { title: "Personalised Shop", slug: "personalised-shop" },
              {
                title: "Add, remove, and edit payment methods on Android",
                slug: "add-remove-and-edit-payment-methods-on-android",
              },
              {
                title: "Add, edit, and remove payment methods on iOS",
                slug: "add-edit-and-remove-payment-methods-on-ios",
              },
              {
                title: "Preventing and managing in-app purchases",
                slug: "preventing-and-managing-in-app-purchases",
              },
              {
                title: "Why is my Gems/Diamonds balance negative?",
                slug: "why-is-my-gemsdiamonds-balance-negative",
              },
              {
                title: "Where do I find my purchase history?",
                slug: "where-do-i-find-my-purchase-history",
              },
            ],
          },
          {
            strapiId: 6,
            title: "NoSurrender Store",
            slug: "nosurrender-store",
            links: [
              {
                title: "What is the NoSurrender Store?",
                slug: "what-is-the-nosurrender-store",
              },
              {
                title: "Purchasing a Gold Pass",
                slug: "purchasing-a-gold-pass",
              },
              {
                title: "Managing your Gold Passes",
                slug: "managing-your-gold-passes",
              },
              { title: "Donating Gold Passes", slug: "donating-gold-passes" },
              {
                title: "Help with store refunds",
                slug: "help-with-store-refunds",
              },
              {
                title: "Finding your store receipt",
                slug: "finding-your-store-receipt",
              },
              {
                title: "Help with store purchases",
                slug: "help-with-store-purchases",
              },
            ],
          },
        ],
      },
      {
        strapiId: 3,
        title: "Gameplay",
        slug: "gameplay",
        subCategories: [
          {
            strapiId: 8,
            title: "Settings",
            slug: "settings",
            links: [
              {
                title: "How to Change Your Username",
                slug: "how-to-change-your-username",
              },
              {
                title: "How to Disable Notifications",
                slug: "how-to-disable-notifications",
              },
              { title: "Maintenance Breaks", slug: "maintenance-breaks" },
              {
                title: "Friends & the Social tab",
                slug: "friends-and-the-social-tab",
              },
            ],
          },
        ],
      },
      {
        strapiId: 5,
        title: "Troubleshooting",
        slug: "troubleshooting",
        subCategories: [
          {
            strapiId: 9,
            title: "Technical Issues",
            slug: "technical-issues",
            links: [
              {
                title: "How to Start a New Game",
                slug: "how-to-start-a-new-game",
              },
              { title: "Unstable Connection", slug: "unstable-connection" },
              {
                title: "How do I reinstall the game?",
                slug: "how-do-i-reinstall-the-game",
              },
              { title: "Sending Bug Reports", slug: "sending-bug-reports" },
              { title: "My game crashes!", slug: "my-game-crashes!" },
            ],
          },
          {
            strapiId: 10,
            title: "Feedback",
            slug: "feedback",
            links: [
              { title: "Sending Feedback", slug: "sending-feedback" },
              { title: "Contact Form", slug: "contact-form" },
            ],
          },
        ],
      },
      {
        strapiId: 4,
        title: "Fair & Safeplay",
        slug: "fair-and-safeplay",
        subCategories: [
          {
            strapiId: 11,
            title: "Banned Accounts",
            slug: "banned-accounts",
            links: [{ title: "Why was I banned?", slug: "why-was-i-banned" }],
          },
          {
            strapiId: 12,
            title: "Code of Conduct",
            slug: "code-of-conduct",
            links: [
              {
                title: "Someone else is playing my account!",
                slug: "someone-else-is-playing-my-account!",
              },
              { title: "Don't be a cheat!", slug: "don't-be-a-cheat!" },
              {
                title: "Cheaper Gems/Diamonds are a scam",
                slug: "cheaper-gemsdiamonds-are-a-scam",
              },
              {
                title: "A bought account is never yours",
                slug: "a-bought-account-is-never-yours",
              },
              {
                title: "Phishing for accounts is not okay!",
                slug: "phishing-for-accounts-is-not-okay!",
              },
              {
                title: "NoSurrender and Third Party Licenses",
                slug: "nosurrender-and-third-party-licenses",
              },
              {
                title: "List of Unaccepted Behaviour",
                slug: "list-of-unaccepted-behaviour",
              },
              {
                title: "Sharing your account is never okay!",
                slug: "sharing-your-account-is-never-okay!",
              },
            ],
          },
          {
            strapiId: 13,
            title: "Taking Action",
            slug: "taking-action",
            links: [
              {
                title: "Reporting an offensive player",
                slug: "reporting-an-offensive-player",
              },
              {
                title: "What does Supercell do to employ Fair Play?",
                slug: "what-does-supercell-do-to-employ-fair-play",
              },
            ],
          },
          {
            strapiId: 14,
            title: "For Parents",
            slug: "for-parents",
            links: [
              {
                title: "What is Safe and Fair Play?",
                slug: "what-is-safe-and-fair-play",
              },
              { title: "Parent's Guide", slug: "parent's-guide" },
            ],
          },
        ],
      },
    ],
  },
  subcategories: {
    group: [
      {
        categoryId: "1",
        nodes: [
          {
            strapiId: 1,
            title: "Recover Your Account",
            slug: "recover-your-account",
            links: [
              { title: "I lost my account!", slug: "i-lost-my-account!" },
              {
                title: "Recover your NoSurrender ID",
                slug: "recover-your-nosurrender-id",
              },
              {
                title: "How can I keep my account safe?",
                slug: "how-can-i-keep-my-account-safe",
              },
              {
                title: "Someone else is playing my account!",
                slug: "someone-else-is-playing-my-account!",
              },
            ],
          },
          {
            strapiId: 2,
            title: "NoSurrender ID",
            slug: "nosurrender-id",
            links: [
              { title: "About NoSurrender ID", slug: "about-nosurrender-id" },
              {
                title: "Save your account with NoSurrender ID",
                slug: "save-your-account-with-nosurrender-id",
              },
              {
                title: "Help with Verification Codes",
                slug: "help-with-verification-codes",
              },
              {
                title: "Playing with multiple game accounts",
                slug: "playing-with-multiple-game-accounts",
              },
              {
                title: "How to opt-out of marketing messages",
                slug: "how-to-opt-out-of-marketing-messages",
              },
            ],
          },
          {
            strapiId: 3,
            title: "Access/Delete Your Data",
            slug: "accessdelete-your-data",
            links: [
              { title: "About GDPR", slug: "about-gdpr" },
              {
                title: "Request your personal data",
                slug: "request-your-personal-data",
              },
              {
                title: "Request deletion of your personal data",
                slug: "request-deletion-of-your-personal-data",
              },
            ],
          },
        ],
      },
      {
        categoryId: "2",
        nodes: [
          {
            strapiId: 4,
            title: "Missing Purchase",
            slug: "missing-purchase",
            links: [
              {
                title: "I didn't get my Android purchase!",
                slug: "i-didn't-get-my-android-purchase!",
              },
              {
                title: "I didn't get my iOS purchase!",
                slug: "i-didn't-get-my-ios-purchase!",
              },
              { title: "I can't buy offers!", slug: "i-can't-buy-offers!" },
            ],
          },
          {
            strapiId: 5,
            title: "In-App Purchases",
            slug: "in-app-purchases",
            links: [
              { title: "Personalised Shop", slug: "personalised-shop" },
              {
                title: "Add, remove, and edit payment methods on Android",
                slug: "add-remove-and-edit-payment-methods-on-android",
              },
              {
                title: "Add, edit, and remove payment methods on iOS",
                slug: "add-edit-and-remove-payment-methods-on-ios",
              },
              {
                title: "Preventing and managing in-app purchases",
                slug: "preventing-and-managing-in-app-purchases",
              },
              {
                title: "Why is my Gems/Diamonds balance negative?",
                slug: "why-is-my-gemsdiamonds-balance-negative",
              },
              {
                title: "Where do I find my purchase history?",
                slug: "where-do-i-find-my-purchase-history",
              },
            ],
          },
          {
            strapiId: 6,
            title: "NoSurrender Store",
            slug: "nosurrender-store",
            links: [
              {
                title: "What is the NoSurrender Store?",
                slug: "what-is-the-nosurrender-store",
              },
              {
                title: "Purchasing a Gold Pass",
                slug: "purchasing-a-gold-pass",
              },
              {
                title: "Managing your Gold Passes",
                slug: "managing-your-gold-passes",
              },
              { title: "Donating Gold Passes", slug: "donating-gold-passes" },
              {
                title: "Help with store refunds",
                slug: "help-with-store-refunds",
              },
              {
                title: "Finding your store receipt",
                slug: "finding-your-store-receipt",
              },
              {
                title: "Help with store purchases",
                slug: "help-with-store-purchases",
              },
            ],
          },
        ],
      },
      {
        categoryId: "3",
        nodes: [
          {
            strapiId: 8,
            title: "Settings",
            slug: "settings",
            links: [
              {
                title: "How to Change Your Username",
                slug: "how-to-change-your-username",
              },
              {
                title: "How to Disable Notifications",
                slug: "how-to-disable-notifications",
              },
              { title: "Maintenance Breaks", slug: "maintenance-breaks" },
              {
                title: "Friends & the Social tab",
                slug: "friends-and-the-social-tab",
              },
            ],
          },
        ],
      },
      {
        categoryId: "4",
        nodes: [
          {
            strapiId: 11,
            title: "Banned Accounts",
            slug: "banned-accounts",
            links: [{ title: "Why was I banned?", slug: "why-was-i-banned" }],
          },
          {
            strapiId: 12,
            title: "Code of Conduct",
            slug: "code-of-conduct",
            links: [
              {
                title: "Someone else is playing my account!",
                slug: "someone-else-is-playing-my-account!",
              },
              { title: "Don't be a cheat!", slug: "don't-be-a-cheat!" },
              {
                title: "Cheaper Gems/Diamonds are a scam",
                slug: "cheaper-gemsdiamonds-are-a-scam",
              },
              {
                title: "A bought account is never yours",
                slug: "a-bought-account-is-never-yours",
              },
              {
                title: "Phishing for accounts is not okay!",
                slug: "phishing-for-accounts-is-not-okay!",
              },
              {
                title: "NoSurrender and Third Party Licenses",
                slug: "nosurrender-and-third-party-licenses",
              },
              {
                title: "List of Unaccepted Behaviour",
                slug: "list-of-unaccepted-behaviour",
              },
              {
                title: "Sharing your account is never okay!",
                slug: "sharing-your-account-is-never-okay!",
              },
            ],
          },
          {
            strapiId: 13,
            title: "Taking Action",
            slug: "taking-action",
            links: [
              {
                title: "Reporting an offensive player",
                slug: "reporting-an-offensive-player",
              },
              {
                title: "What does Supercell do to employ Fair Play?",
                slug: "what-does-supercell-do-to-employ-fair-play",
              },
            ],
          },
          {
            strapiId: 14,
            title: "For Parents",
            slug: "for-parents",
            links: [
              {
                title: "What is Safe and Fair Play?",
                slug: "what-is-safe-and-fair-play",
              },
              { title: "Parent's Guide", slug: "parent's-guide" },
            ],
          },
        ],
      },
      {
        categoryId: "5",
        nodes: [
          {
            strapiId: 9,
            title: "Technical Issues",
            slug: "technical-issues",
            links: [
              {
                title: "How to Start a New Game",
                slug: "how-to-start-a-new-game",
              },
              { title: "Unstable Connection", slug: "unstable-connection" },
              {
                title: "How do I reinstall the game?",
                slug: "how-do-i-reinstall-the-game",
              },
              { title: "Sending Bug Reports", slug: "sending-bug-reports" },
              { title: "My game crashes!", slug: "my-game-crashes!" },
            ],
          },
          {
            strapiId: 10,
            title: "Feedback",
            slug: "feedback",
            links: [
              { title: "Sending Feedback", slug: "sending-feedback" },
              { title: "Contact Form", slug: "contact-form" },
            ],
          },
        ],
      },
    ],
  },
  subCategoryBased: {
    group: [
      {
        subcategoryId: "1",
        nodes: [
          { title: "I lost my account!", slug: "i-lost-my-account!" },
          {
            title: "Recover your NoSurrender ID",
            slug: "recover-your-nosurrender-id",
          },
          {
            title: "How can I keep my account safe?",
            slug: "how-can-i-keep-my-account-safe",
          },
          {
            title: "Someone else is playing my account!",
            slug: "someone-else-is-playing-my-account!",
          },
        ],
      },
      {
        subcategoryId: "10",
        nodes: [
          { title: "Sending Feedback", slug: "sending-feedback" },
          { title: "Contact Form", slug: "contact-form" },
        ],
      },
      {
        subcategoryId: "11",
        nodes: [{ title: "Why was I banned?", slug: "why-was-i-banned" }],
      },
      {
        subcategoryId: "12",
        nodes: [
          {
            title: "Someone else is playing my account!",
            slug: "someone-else-is-playing-my-account!",
          },
          { title: "Don't be a cheat!", slug: "don't-be-a-cheat!" },
          {
            title: "Cheaper Gems/Diamonds are a scam",
            slug: "cheaper-gemsdiamonds-are-a-scam",
          },
          {
            title: "A bought account is never yours",
            slug: "a-bought-account-is-never-yours",
          },
          {
            title: "Phishing for accounts is not okay!",
            slug: "phishing-for-accounts-is-not-okay!",
          },
          {
            title: "NoSurrender and Third Party Licenses",
            slug: "nosurrender-and-third-party-licenses",
          },
          {
            title: "List of Unaccepted Behaviour",
            slug: "list-of-unaccepted-behaviour",
          },
          {
            title: "Sharing your account is never okay!",
            slug: "sharing-your-account-is-never-okay!",
          },
        ],
      },
      {
        subcategoryId: "13",
        nodes: [
          {
            title: "Reporting an offensive player",
            slug: "reporting-an-offensive-player",
          },
          {
            title: "What does Supercell do to employ Fair Play?",
            slug: "what-does-supercell-do-to-employ-fair-play",
          },
        ],
      },
      {
        subcategoryId: "14",
        nodes: [
          {
            title: "What is Safe and Fair Play?",
            slug: "what-is-safe-and-fair-play",
          },
          { title: "Parent's Guide", slug: "parent's-guide" },
        ],
      },
      {
        subcategoryId: "2",
        nodes: [
          { title: "About NoSurrender ID", slug: "about-nosurrender-id" },
          {
            title: "Save your account with NoSurrender ID",
            slug: "save-your-account-with-nosurrender-id",
          },
          {
            title: "Help with Verification Codes",
            slug: "help-with-verification-codes",
          },
          {
            title: "Playing with multiple game accounts",
            slug: "playing-with-multiple-game-accounts",
          },
          {
            title: "How to opt-out of marketing messages",
            slug: "how-to-opt-out-of-marketing-messages",
          },
        ],
      },
      {
        subcategoryId: "3",
        nodes: [
          { title: "About GDPR", slug: "about-gdpr" },
          {
            title: "Request your personal data",
            slug: "request-your-personal-data",
          },
          {
            title: "Request deletion of your personal data",
            slug: "request-deletion-of-your-personal-data",
          },
        ],
      },
      {
        subcategoryId: "4",
        nodes: [
          {
            title: "I didn't get my Android purchase!",
            slug: "i-didn't-get-my-android-purchase!",
          },
          {
            title: "I didn't get my iOS purchase!",
            slug: "i-didn't-get-my-ios-purchase!",
          },
          { title: "I can't buy offers!", slug: "i-can't-buy-offers!" },
        ],
      },
      {
        subcategoryId: "5",
        nodes: [
          { title: "Personalised Shop", slug: "personalised-shop" },
          {
            title: "Add, remove, and edit payment methods on Android",
            slug: "add-remove-and-edit-payment-methods-on-android",
          },
          {
            title: "Add, edit, and remove payment methods on iOS",
            slug: "add-edit-and-remove-payment-methods-on-ios",
          },
          {
            title: "Preventing and managing in-app purchases",
            slug: "preventing-and-managing-in-app-purchases",
          },
          {
            title: "Why is my Gems/Diamonds balance negative?",
            slug: "why-is-my-gemsdiamonds-balance-negative",
          },
          {
            title: "Where do I find my purchase history?",
            slug: "where-do-i-find-my-purchase-history",
          },
        ],
      },
      {
        subcategoryId: "6",
        nodes: [
          {
            title: "What is the NoSurrender Store?",
            slug: "what-is-the-nosurrender-store",
          },
          { title: "Purchasing a Gold Pass", slug: "purchasing-a-gold-pass" },
          {
            title: "Managing your Gold Passes",
            slug: "managing-your-gold-passes",
          },
          { title: "Donating Gold Passes", slug: "donating-gold-passes" },
          { title: "Help with store refunds", slug: "help-with-store-refunds" },
          {
            title: "Finding your store receipt",
            slug: "finding-your-store-receipt",
          },
          {
            title: "Help with store purchases",
            slug: "help-with-store-purchases",
          },
        ],
      },
      {
        subcategoryId: "8",
        nodes: [
          {
            title: "How to Change Your Username",
            slug: "how-to-change-your-username",
          },
          {
            title: "How to Disable Notifications",
            slug: "how-to-disable-notifications",
          },
          { title: "Maintenance Breaks", slug: "maintenance-breaks" },
          {
            title: "Friends & the Social tab",
            slug: "friends-and-the-social-tab",
          },
        ],
      },
      {
        subcategoryId: "9",
        nodes: [
          { title: "How to Start a New Game", slug: "how-to-start-a-new-game" },
          { title: "Unstable Connection", slug: "unstable-connection" },
          {
            title: "How do I reinstall the game?",
            slug: "how-do-i-reinstall-the-game",
          },
          { title: "Sending Bug Reports", slug: "sending-bug-reports" },
          { title: "My game crashes!", slug: "my-game-crashes!" },
        ],
      },
    ],
  },
}
